<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
</script>

<template>
  <div>
    <slot />
  </div>
</template>

<style lang='scss' scoped></style>